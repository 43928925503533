import { httpClientAuth } from '@/libraries';
import { transFormer } from '@/utils';

export default {
    async login(data = {}) {
        const response = await httpClientAuth.post('/login', data);
        if (response.data.message == 'input mfa') {
            return transFormer.response(response);
        }
        if (response.data.success) {
            this.createStorage(response);
        }
        return transFormer.response(response);
    },
    async verify2fa(data = {}) {
        const response = await httpClientAuth.post('/verify2fa', data);
        if (response.data.success) {
            return transFormer.response(response);
        }
    },
    createStorage(response) {
        localStorage.setItem('user', JSON.stringify(response.data.data));
    },
    async register(page, data) {
        const response = await httpClientAuth.post('/register/' + page, data);
        return transFormer.response(response);
    },
    async forgot(email) {
        const response = await httpClientAuth.post('/forgot-password', { email });
        return transFormer.response(response);
    },
    removeStorage() {
        localStorage.removeItem('user');
    },
    async logout(token) {
        httpClientAuth.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
        const response = await httpClientAuth.post('/logout');
        return transFormer.response(response);
    },
    async resetPassword(token, data) {
        const response = await httpClientAuth.post(`/reset-password/${token}`, data);
        return transFormer.response(response);
    },
    async resetPasswordWithoutRequest(data) {
        const response = await httpClientAuth.post('/forgot-password-without-request', data);
        return transFormer.response(response);
    },
    async resendMfaCode(email) {
        const response = await httpClientAuth.post('/resend-mfa', email);
        return transFormer.response(response);
    },
    async removeMfaCode(email) {
        const response = await httpClientAuth.post('/remove-mfa-code', {
            email: email
        });
        localStorage.removeItem('loginData');
    },
    async v3Login(data = {}) {
        const response = await httpClientAuth.post('/v3/login', data);
        if (response.data.message == 'input mfa') {
            return transFormer.response(response);
        }
        if (response.data.success) {
            this.createStorage(response);
        }
        return transFormer.response(response);
    },
    async loginWithGoogle(data) {
        const response = await httpClientAuth.post('/login-google', data);
        if (response.data.success && response.data.statuscode != 2002 && response.data.statuscode != 2003) {
            this.createStorage(response);
        }
        return transFormer.response(response);
    },
    async getRegistrationStep(data) {
        const response = await httpClientAuth.post('/registration-step', data);
        return transFormer.response(response);
    },
    async verifyEmail(token) {
        const response = await httpClientAuth.get('/verify/' + token);
        return transFormer.response(response);
    },
    async getCompanyIndustry() {
        const response = await httpClientAuth.get('/register/company-industries');
        return transFormer.response(response);
    },
    async resendVerification(email) {
        const response = await httpClientAuth.post('/resendconfirm', { email });
        return transFormer.response(response);
    },
    async checkResetPasswordToken(token) {
        const response = await httpClientAuth.get('/check-reset-password-token/' + token);
        return transFormer.response(response);
    },
    async sendCreatePassword(email) {
        const response = await httpClientAuth.post('/create-password', { email });
        return transFormer.response(response);
    },
    async sso(data = {}) {
        const response = await httpClientAuth.post('/sso', data);
        if (response.data.success) {
            this.createStorage(response);
        }
        return transFormer.response(response);
    }
};
