import { httpClientOmni } from "@/libraries";

const state = () => ({
  products: [],
});

const getters = {};

const mutations = {
  SET_PRODUCT: (state, data) => {
    state.products = [...state.products, ...data];
  },

  ADD_PRODUCT: (state, product) => {
    state.products = [...state.products, product];
  },
};

const actions = {
  addProduct({ state, commit }, data) {
    const check = state.products.find((product) => product.slug === data.slug);
    if (!check) {
      commit("ADD_PRODUCT", data);
    }
  },

  async getProducts({ state, commit }, slug = []) {
    const slugFiltered = slug.filter((r) => {
      return !state.products.find((product) => product.slug === r);
    });

    const endpoint = `product?slug=${slugFiltered.join(
      "|"
    )}&without_pagination=1`;

    const req = await httpClientOmni.get(endpoint);

    if (req.status === 200) {
      commit("SET_PRODUCT", req.data);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
