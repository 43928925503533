import { httpClientOmni } from "@/libraries";

const state = {
  loading: false,
  rooms: [],
  roomGroups: [],
  contactList: [],
  groups: [],
  loadedMessage: [],
  chats: [],
  onlineMember: [],

  chatActive: {
    id: null,
    isGroup: null,
  },
};

const getters = {
  getContactList(state) {
    return state.contactList;
  },

  getGroups(state) {
    return state.groups;
  },

  getLoading(state) {
    return state.loading;
  },

  getChats(state) {
    return state.chats;
  },

  getOnlineMembers(state) {
    return state.onlineMember;
  },

  getRooms(state) {
    return state.rooms;
  },

  getRoomGroups(state) {
    return state.roomGroups;
  },
};

const mutations = {
  SET_LOADING: (state, payload) => (state.loading = payload),
  SET_CONTACT_LIST: (state, payload) => (state.contactList = payload),
  SET_GROUPS: (state, payload) => (state.groups = payload),

  SET_LOADED_MESSAGE: (state, payload) => state.loadedMessage.push(payload),
  RESET_LOADED_MESSAGE: (state) => (state.loadedMessage = []),

  APPEND_CHATS: (state, payload) => {
    state.chats = [...state.chats, ...payload];
  },

  SET_ONLINE_MEMBER: (state, payload) => {
    state.onlineMember = payload;
  },

  CHANGE_ONLINE_MEMBER: (state, payload) => {
    const index = state.onlineMember.findIndex(
      (r) => payload.userId === r.userId
    );

    if (index >= 0) {
      state.onlineMember[index].status = payload.status;
    }
  },

  SET_ROOMS: (state, payload) => {
    state.rooms = payload;
  },

  APPEND_ROOM: (state, payload) => {
    state.rooms = [payload, ...state.rooms];
  },

  CHANGE_ROOM: (state, { data, index }) => {
    if (data.isRead !== undefined) state.rooms[index].isRead = data.isRead;
    if (data.message !== undefined) state.rooms[index].message = data.message;
    if (data.createdAt !== undefined)
      state.rooms[index].createdAt = data.createdAt;
    if (data.countUnread !== undefined) {
      if (data.countUnread === "increment") {
        state.rooms[index].countUnread = state.rooms[index].countUnread + 1;
      } else {
        state.rooms[index].countUnread = data.countUnread;
      }
    }
  },

  SET_ROOM_GROUPS: (state, payload) => {
    state.roomGroups = payload;
  },

  SET_CHAT_ACTIVE: (state, payload) => {
    state.chatActive.id = payload.id;
    state.chatActive.isGroup = payload.isGroup;
  },
};

const actions = {
  async getContactList({ commit, getters }) {
    if (!getters.getContactList.length) {
      commit("SET_LOADING", true);
      const req = await httpClientOmni.get("v3/member/all");
      commit("SET_LOADING", false);
      const res = req.data.map((r) => ({
        ...r,
        color: Math.floor(Math.random() * 4),
      }));
      commit("SET_CONTACT_LIST", res);

      return res;
    }

    return getters.getContactList;
  },

  async getGroup({ commit, getters }, refresh = false) {
    if (!getters.getGroups.length || refresh) {
      commit("SET_LOADING", true);
      const req = await httpClientOmni.get("v3/group/mine");
      commit("SET_LOADING", false);
      commit("SET_GROUPS", req.data.data);

      return req.data.data;
    }

    return getters.getGroups;
  },

  async newMessage({ state, commit, dispatch, rootState }, data) {
    const userId = rootState["localStorage"].decodedUserId;

    const index = state.rooms.findIndex(
      (r) => r.userId === data.fromId || r.userId == data.toId
    );

    if (index >= 0) {
      let countUnread = 0;
      if (!state.chatActive.isGroup) {
        if (userId != data.fromId && state.chatActive.id !== data.fromId) {
          countUnread = "increment";
        }
      }

      commit("CHANGE_ROOM", {
        index,
        data: {
          ...data,
          isRead: countUnread !== 0,
          countUnread,
        },
      });

      dispatch("addChat", data);
    } else {
      commit("APPEND_ROOM", {
        userId: data.fromId !== parseInt(userId) ? data.fromId : data.toId,
        isRead: false,
        isGroup: false,
        createdAt: data.createdAt,
        message: data.message,
        countUnread: data.fromId !== userId ? 1 : 0,
      });

      await dispatch(
        "addLoadedMessage",
        `user-${data.fromId !== parseInt(userId) ? data.fromId : data.toId}`
      );

      dispatch("appendChat", [data]);
    }
  },

  addLoadedMessage({ state, commit }, payload) {
    const check = state.loadedMessage.findIndex((r) => r === payload);
    if (check >= 0) return false;

    commit("SET_LOADED_MESSAGE", payload);
    return true;
  },

  appendChat({ commit }, payload) {
    commit("APPEND_CHATS", payload);
  },

  addChat({ commit }, payload) {
    commit("APPEND_CHATS", [payload]);
  },

  setOnlineMember({ commit }, payload) {
    commit("SET_ONLINE_MEMBER", payload);
  },

  changeOnlineMember({ commit }, payload) {
    commit("CHANGE_ONLINE_MEMBER", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
