const settingsRoute = [
  {
    path: "/:appId/setting",
    name: "settings",
    component: () => import("@/views/settings/pages/SettingMain.vue"),
    meta: { title: "Settings" },
  },
  {
    path: "/:appId/setting/accountbilling/detail",
    name: "settings.account-and-billing.account-details",
    component: () => import("@/views/settings/pages/SettingMain.vue"),
    meta: { title: "Account Billing" },
  },
  {
    path: "/:appId/setting/accountbilling/plan",
    name: "settings.account-and-billing.billing-plans",
    component: () => import("@/views/settings/pages/SettingMain.vue"),
    meta: { title: "Billing Plan" },
  },
  {
    path: "/:appId/setting/accountbilling/audit",
    name: "settings.account-and-billing.audit-trails",
    component: () => import("@/views/settings/pages/SettingMain.vue"),
    meta: { title: "Billing Plan" },
  },
  {
    path: "/:appId/setting/agent/group",
    name: "settings.agent.group-management",
    component: () =>
      import("@/views/settings/pages/GroupManagementListPage.vue"),
    meta: { title: "Group Management" },
  },
  {
    path: "/:appId/setting/agent/team",
    name: "settings.agent.team-management",
    component: () => import("@/views/settings/pages/TeamManagementPage.vue"),
    meta: { title: "Team Management" },
  },
  {
    path: "/:appId/setting/agent/role",
    name: "settings.agent.role-management",
    component: () => import("@/views/settings/pages/RoleManagementPage.vue"),
    meta: { title: "Role Management" },
    props: true,
  },
  {
    path: "/:appId/setting/bot/configuration",
    name: "settings.bot.bot-persona",
    component: () => import("@/views/settings/pages/SettingMain.vue"),
    meta: { title: "Bot Configuration" },
  },
  {
    path: "/:appId/setting/bot/response",
    name: "settings.bot.response-configuration",
    component: () => import("@/views/settings/pages/SettingMain.vue"),
    meta: { title: "Response Configuration" },
  },
  {
    path: "/:appId/setting/chat/auto-response",
    name: "settings.chat.auto-response-and-office-hours",
    component: () =>
      import("@/views/settings/pages/AutoResponseOfficeHour.vue"),
    meta: { title: "Auto Response & Office Hour" },
  },
  {
    path: "/:appId/setting/chat/template",
    name: "settings.chat.message-template",
    component: () => import("@/views/settings/pages/ChatTemplatePage.vue"),
    meta: { title: "Message Template" },
  },
  {
    path: "/:appId/setting/chat/tag",
    name: "settings.chat.tag-label-configuration",
    component: () => import("@/views/ticketing/pages/MasterData/TagPage.vue"),
    meta: { title: "Tag Label" },
  },
  {
    path: "/:appId/setting/chat/follow-up-message",
    name: "settings.chat.follow-up-message",
    component: () => import("@/views/settings/pages/FollowUpMessagePage.vue"),
    meta: { title: "Follow Up Message" },
  },
  {
    path: "/:appId/setting/chat/dynamic-form",
    name: "settings.chat.dynamic-form",
    component: () => import("@/views/settings/pages/DynamicForm.vue"),
    meta: { title: "Follow Up Message" },
  },
  {
    path: "/:appId/setting/chat/notification-sound",
    name: "settings.chat.notification-sound",
    component: () => import("@/views/settings/pages/NotificationSound.vue"),
    meta: { title: "Notification Sound" },
  },
  {
    path: "/:appId/setting/notification-sound",
    name: "settings.notification-sound",
    component: () => import("@/views/settings/pages/NotificationSound.vue"),
    meta: { title: "Notification Sound" },
  },
  {
    path: "/:appId/setting/general/profile",
    name: "settings.general.profile-setting",
    component: () =>
      import("@/views/settings/pages/profile/ProfileDisplay.vue"),
    meta: { title: "Profile Setting" },
  },
  {
    path: "/:appId/setting/general/config",
    name: "settings.general.project-config",
    component: () => import("@/views/settings/pages/AppConfigurationPage.vue"),
    meta: { title: "Project Configuration" },
  },
  {
    path: "/:appId/setting/general/app-content",
    name: "settings.general.app-content",
    component: () => import("@/views/settings/pages/AppContentListPage.vue"),
    meta: { title: "App Content" },
  },
  {
    path: "/:appId/setting/general/value",
    name: "settings.general.general-value",
    component: () => import("@/views/settings/pages/GeneralValuePage.vue"),
    meta: { title: "General Value" },
  },
  {
    path: "/:appId/setting/general/integration",
    name: "settings.general.integration",
    component: () => import("@/views/integration/pages/IntegrationPage.vue"),
    meta: { title: "Integration" },
    redirect: { name: "integration.whatsapp" },
  },
  {
    path: "/:appId/setting/resource/product",
    name: "settings.resources.product",
    component: () => import("@/views/settings/pages/ProductListPage.vue"),
    meta: { title: "Products" },
  },
  {
    path: "/:appId/setting/resource/product-add",
    name: "settings.resources.product-add",
    component: () => import("@/views/settings/pages/ProductAddPage.vue"),
    meta: { title: "Add Product" },
  },
  {
    path: "/:appId/setting/resource/product-detail",
    name: "settings.resources.product-details",
    component: () => import("@/views/settings/pages/ProductEditPage.vue"),
    meta: { title: "Detail Product" },
  },
  {
    path: "/:appId/setting/resource/category",
    name: "settings.resources.product-category",
    component: () => import("@/views/settings/pages/SettingMain.vue"),
    meta: { title: "Product Category" },
  },
  {
    path: "/:appId/setting/resource/ppob",
    name: "settings.resources.ppob-products",
    component: () => import("@/views/settings/pages/SettingMain.vue"),
    meta: { title: "PPOB Product" },
  },
  {
    path: "/:appId/setting/resource/config",
    name: "settings.resources.resource",
    component: () => import("@/views/settings/pages/SettingMain.vue"),
    meta: { title: "Resource Config" },
  },
  {
    path: "/:appId/setting/resource/voucher",
    name: "settings.resources.voucher",
    component: () => import("@/views/voucher/pages/VoucherPage.vue"),
    meta: { title: "Voucher Page" },
  },
  {
    path: "/:appId/setting/security/block-ip",
    name: "settings.security.ip-blocker",
    component: () => import("@/views/settings/pages/SettingMain.vue"),
    meta: { title: "IP Blocker" },
  },
  {
    path: "/:appId/setting/security/block-customer",
    name: "settings.security.customer-block",
    component: () => import("@/views/settings/pages/CustomerBlocker.vue"),
    meta: { title: "Customer Blocker" },
  },
  {
    path: "/:appId/setting/security/block-access",
    name: "settings.security.block-access-customer",
    component: () => import("@/views/settings/pages/BlockAccessCustomer.vue"),
    meta: { title: "Block Access Customer" },
  },
  {
    path: "/:appId/setting/security/block-sourcefund",
    name: "settings.security.source-fund-blocker",
    component: () => import("@/views/settings/pages/SourceFundBlockerPage.vue"),
    meta: { title: "Source Fund Blocker" },
  },
  {
    path: "/:appId/setting/ticket/status",
    name: "settings.ticketing.status-ticket",
    component: () =>
      import("@/views/ticketing/pages/MasterData/StatusPage.vue"),
    meta: { title: "Status Ticket" },
  },
  {
    path: "/:appId/setting/ticket/priority",
    name: "settings.ticketing.priority-ticket",
    meta: { title: "Priority Ticket" },
    component: () =>
      import("@/views/ticketing/pages/MasterData/PriorityPage.vue"),
  },
  {
    path: "/:appId/setting/ticket/type",
    name: "settings.ticketing.type-ticket",
    component: () => import("@/views/ticketing/pages/MasterData/TypePage.vue"),
    meta: { title: "Type Ticket" },
  },
  {
    path: "/:appId/setting/ticket/category",
    name: "settings.ticketing.category-ticket",
    component: () =>
      import(
        "@/views/ticketing/pages/MasterData/category/CategoryMainPage.vue"
      ),
    meta: { title: "Category Ticket" },
  },
  {
    path: "/:appId/setting/ticket/subject",
    name: "settings.ticketing.subject",
    component: () =>
      import("@/views/ticketing/pages/MasterData/SubjectPage.vue"),
    meta: { title: "Subject Ticket" },
  },
  {
    path: "/:appId/setting/ticket/branch-office",
    name: "settings.ticketing.branch-office-ticket",
    component: () =>
      import("@/views/ticketing/pages/MasterData/BranchOfficePage.vue"),
    meta: { title: "Business Unit" },
  },
  {
    path: "/:appId/setting/ticket/content-restrict",
    name: "settings.ticketing.content-restricted",
    component: () =>
      import("@/views/ticketing/pages/MasterData/ContentRestrictedPage.vue"),
    meta: { title: "Content Restricted Ticket" },
  },
  {
    path: "/:appId/setting/ticket/auto-reminder",
    name: "settings.ticketing.auto-reminder-office-hour",
    component: () =>
      import(
        "@/views/ticketing/pages/MasterData/AutoReminderOfficeHourPage.vue"
      ),
    meta: { title: "Auto Reminder & Office Hour" },
  },
  {
    path: "/:appId/setting/ticket/field",
    name: "settings.ticketing.ticket-field",
    component: () =>
      import("@/views/ticketing/pages/ticket-field/TicketFieldPage.vue"),
    meta: { title: "Ticket Field" },
  },
  {
    path: "/:appId/setting/ticket/content-template",
    name: "settings.ticketing.content-template",
    component: () =>
      import("@/views/settings/pages/content-template/ContentTemplateMain.vue"),
    meta: { title: "Content Template" },
  },
  {
    path: "/:appId/setting/change-password",
    name: "settings.change-password",
    component: () =>
      import("@/views/settings/pages/profile/ChangePassword.vue"),
    meta: { title: "Profile Setting" },
  },
  {
    path: '/:appId/setting/reply-material',
    name: 'settings.reply-material',
    component: () => import('@/views/settings/pages/ReplyMaterialPage.vue'),
    meta: { title: 'Reply Material' }
  },
  {
    path: "/:appId/setting/conversation-label",
    name: "settings.chat.conversation-label",
    component: () =>
      import(
        "@/views/settings/pages/conversation-label/ConversationLabelPage.vue"
      ),
    meta: { title: "Conversation Label" },
  },
];
export default settingsRoute;
